<template>
  <footer class="dvn-site-footer">
    <div class="dvn-site-footer-top">
      <div class="dvn-site-footer-links">
        <NuxtLink :to="localeLink('site-desktop-download')">DiViNe Desktop</NuxtLink>
        <NuxtLink :to="localeLink('help')">{{ $t('SiteLayoutFooter.Help') }}</NuxtLink>
        <NuxtLink :to="localeLink('site-contact')">{{ $t('SiteLayoutFooter.Contact') }}</NuxtLink>
        <!-- <NuxtLink :to="localeLink('site-about')">{{ $t('SiteLayoutFooter.About') }}</NuxtLink> -->
      </div>
      <NuxtLink class="dvn-site-footer-home-link" :to="localeLink('/')"
        ><SvgLogo width="150"
      /></NuxtLink>
      <div class="dvn-site-footer-links">
        <NuxtLink :to="localeLink('site-youtubers')">{{
          $t('SiteLayoutFooter.Youtubers')
        }}</NuxtLink>

        <!-- <NuxtLink :to="`${localeLink('/')}#support-divine`">{{
          $t('SiteLayoutHeader.Donate')
        }}</NuxtLink> -->

        <NuxtLink to="/terms-and-conditions">{{ $t('SiteLayoutFooter.Terms') }}</NuxtLink>
        <NuxtLink to="/eula">{{ $t('SiteLayoutFooter.EULA') }}</NuxtLink>
        <NuxtLink to="/privacy-policy">{{ $t('SiteLayoutFooter.Privacy') }}</NuxtLink>
      </div>
    </div>

    <div class="dvn-site-footer-pictos-rs">
      <SvgPictoRs name="youtube" width="45" link="https://www.youtube.com/@DiViNeSamples" />
      <SvgPictoRs name="instagram" width="45" link="https://todo..." />
      <!-- <SvgPictoRs name="facebook" width="45" link="https://todo..." /> -->
      <!-- <SvgPictoRs name="twitter" width="45" link="https://todo..." /> -->
    </div>

    <span class="dvn-site-footer-copyright">© DiViNe - {{ new Date().getFullYear() }}</span>
  </footer>
</template>

<style lang="css" scoped>
footer.dvn-site-footer {
  width: 100vw;
  padding: 2em 1em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a.dvn-site-footer-home-link {
  margin: 1em;
}

div.dvn-site-footer-top {
  margin-bottom: 1em;
  padding-bottom: 1em;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

div.dvn-site-footer-links {
  min-width: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

div.dvn-site-footer-links > a {
  margin: 0.25em 1.2em;
  text-align: center;
  color: white;
}

div.dvn-site-footer-links > a:hover {
  color: var(--dvn-color-secondary);
}

div.dvn-site-footer-pictos-rs > a {
  margin: 0 0.75em;
}

span.dvn-site-footer-copyright {
  margin-top: 2em;
}

@media screen and (max-width: 1023px) {
  div.dvn-site-footer-links {
    flex-direction: column;
  }
}

@media screen and (max-width: 539px) {
  div.dvn-site-footer-top {
    flex-direction: column;
  }
}
</style>
